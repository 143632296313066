import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('customer', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchArchiveCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('archivecustomer', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    showCustomerTrade() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('showCustomerTrade')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCustomerById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`customer/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('customer', customerData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`customer/${customerData.id}`, customerData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    contactSendToArchive(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('contactSendToArchive', { id: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchCustomerColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('customer_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
