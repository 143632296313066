<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card>
      <b-row>
        <table class="table">
          <thead>
            <tr>
              <th>Contact Details</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="customerRules">
        <b-form>
          <b-row class="mt-2">
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="firstName">First Name*</label>
                <validation-provider name="First Name" #default="{ errors }" rules="required">
                  <b-form-input id="firstName" v-model="customerData.firstName" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="lastName">Last Name </label>
                <b-form-input id="lastName" v-model="customerData.lastName" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="companyName">Company Name</label>

                <b-form-input id="companyName" v-model="customerData.companyName" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="phoneNumber">Phone Number*</label>
                <validation-provider name="Phone Number" #default="{ errors }" rules="required">
                  <b-form-input id="phoneNumber" v-model="customerData.phoneNumber" :state="errors.length > 0 ? false : null" type="text" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="email">Email*</label>
                <validation-provider name="Email" #default="{ errors }" rules="required">
                  <b-form-input id="email" v-model="customerData.email" :state="errors.length > 0 ? false : null" type="text" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="unitNumber">Unit Number</label>
                <b-form-input id="unitNumber" v-model="customerData.unitNumber" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="provence">Province</label>

                <b-form-input id="provence" v-model="customerData.provence" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="postalCode">Postal Code</label>

                <b-form-input id="postalCode" v-model="customerData.postalCode" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="6">
              <b-form-group>
                <label for="streetAddress">Street Address</label>

                <b-form-input id="streetAddress" v-model="customerData.streetAddress" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="city">City</label>

                <b-form-input id="city" v-model="customerData.city" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="country">Country</label>

                <b-form-input id="country" v-model="customerData.country" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-if="id == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BForm, BCard, BContainer, BFormFile, BTabs, BTab, BBadge, BListGroupItem, BListGroup, BNavItem, BFormTextarea, BFormRadioGroup, BInputGroup, BFormRadio, BAlert, BButton, BOverlay, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import customerStoreModule from '../customerStoreModule';
import store from '@/store';
import vSelect from 'vue-select';
import { onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import VueNumericInput from 'vue-numeric-input';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { required, email } from '@validations';
import router from '@/router';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import moment from 'moment';

export default {
  components: {
    BCard,
    BForm,
    Cleave,

    BContainer,
    BFormFile,
    BTabs,
    BTab,
    flatPickr,
    BBadge,
    BListGroupItem,
    BListGroup,
    BNavItem,
    BCardActions,
    BFormTextarea,
    BFormRadioGroup,
    BInputGroup,
    BFormRadio,
    BAlert,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    ToastificationContent,
    VueNumericInput,
  },
  directives: {
    Ripple,
  },

  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'customer';
    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
    });
  },

  computed: {},

  created() {
    this.getByIdCustomer();
  },

  data() {
    var customerDataModel = {
      phoneNumber: null,
      firstName: null,
      lastName: null,
      email: null,
      unitNumber: null,
      provence: null,
      streetAddress: null,
      city: null,
      postalCode: null,
      country: null,
      gst: null,
      pst: null,
      dealer: null,
      driverLicenseNumber: null,
      otherIdNumber: null,
      dateOfBirth: null,
      companyName: null,
    };

    return {
      balance: 0,
      quoteFields: [
        {
          key: 'id',
          label: 'USER ID',
          class: 'text-center',
          thStyle: 'width: 20%',
        },
        {
          key: 'firstName',
          label: 'FIRST NAME',
          class: 'text-center',
          thStyle: 'width: 25%',
        },
        {
          key: 'lastName',
          label: 'LAST NAME',
          class: 'text-center',
          thStyle: 'width: 25%',
        },
        {
          key: 'email',
          label: 'EMAIL',
          class: 'text-center',
          thStyle: 'width: 30%',
        },
      ],

      balanceFields: [
        {
          key: 'created_at',
          label: 'CREATE DATE',
          class: 'text-center',
          thStyle: 'width: 20%',
        },
        {
          key: 'purchaseId',
          label: 'PURCHASE QUOTE',
          class: 'text-center',
          thStyle: 'width: 25%',
        },
        {
          key: 'saleQuoteId',
          label: 'SALE QUOTE',
          class: 'text-center',
          thStyle: 'width: 25%',
        },
        {
          key: 'cost',
          label: 'AMOUNT',
          class: 'text-center',
          thStyle: 'width: 15%',
        },
        {
          key: 'status',
          label: 'STATUS',
          class: 'text-center',
          thStyle: 'width: 15%',
        },
      ],

      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      id: null,
      formShow: false,
      baseURL: store.state.app.baseURL,
      customerData: customerDataModel,
      required,
      email,
    };
  },

  methods: {
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD/MM/YYYY hh:mm');
      }
    },

    formSubmitted() {
      this.formShow = true;

      this.$refs.customerRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            store
              .dispatch('customer/updateCustomer', this.customerData)
              .then((response) => {
                if (response.data != 'alreadyUpdated') {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Customer Update Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  router.push({
                    name: 'contact-list',
                  });
                } else {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Please refresh the page.',
                      text: '⚠️ Data has been changed during operations. ',
                      icon: 'RefreshCwIcon',
                      variant: 'warning',
                    },
                  });
                }
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Something went wrong',
                    text: 'Please try again or report an issue to support',
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                });
                // router.push({ name: 'quote-list' });
              });
          } else {
            store
              .dispatch('customer/saveCustomer', this.customerData)
              .then((response) => {
                if (response.status == 201) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Customer Insert Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });
                  // this.getByIdCustomer(response.data.id);

                  if (router.currentRoute.name == 'contact-add-purchase') {
                    router.push({
                      name: 'quote-add',
                      params: { id: router.currentRoute.params.quoteid },
                    });
                  } else {
                    router.push({
                      name: 'contact-edit',
                      params: { id: response.data.id },
                    });
                  }
                }
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Something went wrong',
                    text: 'Please try again or report an issue to support',
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                });
                // router.push({ name: 'quote-list' });
              });
          }
        } else {
          this.formShow = false;
        }
      });
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    getByIdCustomer(optionalId = 0) {
      this.formShow = true;

      if (router.currentRoute.params.id || optionalId > 0) {
        this.id = router.currentRoute.params.id ?? optionalId;
        store
          .dispatch('customer/getCustomerById', { id: this.id })
          .then((response) => {
            // response.data.balanceTable = response.data.balanceTable.reverse();
            this.balance = response.data.balance;
            this.customerData = response.data;

            this.formShow = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            // router.push({ name: 'quote-list' });
          });
      } else {
        this.formShow = false;
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.form1.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
